/* @import "compass/css3"; */



.box3d {
    width: 200px;
    height: 200px;
    margin: 100px auto;
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.box3d div {
    position: absolute;
    left: 0;
    top: 0;
    width: 200px;
    height: 200px;
    opacity: 0.5;
    transform-origin: 50% 50% -100px;
}

.p1 {
    border: 1px solid #4cadeb;
    transform: perspective(600px) rotateX(0deg) rotateY(90deg) rotateZ(0deg);
    animation: spin1 10s infinite linear;
}

.p2 {
    border: 1px solid #7ac2f0;
    animation: spin2 10s infinite linear;
}

.p3 {
    border: 1px solid #a8d7f5;
    animation: spin3 10s infinite linear;
}

.p4 {
    border: 1px solid #eb8a4c;
    animation: spin4 10s infinite linear;
}

.p5 {
    border: 1px solid #f0a87a;
    animation: spin5 10s infinite linear;
}

.p6 {
    border: 1px solid #9b9b9b;
    animation: spin6 10s infinite linear;
}


@keyframes spin1 {

    to {
        transform: perspective(600px) rotateX(360deg) rotateY(450deg) rotateZ(0deg);
    }
}

@keyframes spin2 {
    0% {
        transform: perspective(600px) rotateX(0deg) rotateY(270deg) rotateZ(0deg);
    }

    100% {
        transform: perspective(600px) rotateX(360deg) rotateY(630deg) rotateZ(0deg);
    }
}

@keyframes spin3 {
    0% {
        transform: perspective(600px) rotateX(90deg) rotateY(0deg) rotateZ(0deg);
    }

    100% {
        transform: perspective(600px) rotateX(450deg) rotateY(0deg) rotateZ(-360deg);
    }
}

@keyframes spin4 {
    0% {
        transform: perspective(600px) rotateX(-90deg) rotateY(0deg) rotateZ(0deg);
    }

    100% {
        transform: perspective(600px) rotateX(270deg) rotateY(0deg) rotateZ(360deg);
    }
}

@keyframes spin5 {
    0% {
        transform: perspective(600px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }

    100% {
        transform: perspective(600px) rotateX(360deg) rotateY(360deg) rotateZ(0deg);
    }
}

@keyframes spin6 {
    0% {
        transform: perspective(600px) rotateX(0deg) rotateY(180deg) rotateZ(0deg);
    }

    100% {
        transform: perspective(600px) rotateX(360deg) rotateY(540deg) rotateZ(0deg);
    }
}