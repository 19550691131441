.root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fill-available;
  width: 320px;
  height: 320px;
}

.circleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 220px;
  border: 2px solid rgb(5, 240, 212);
  border-radius: 50%;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.circleContainer:hover {
  /* -moz-box-shadow: 0px 0px 0px 1px rgba(5, 240, 212, 0.1),
    0px 0px 1px 1px rgba(5, 240, 212, 0.1),
    inset 0px 0px 1px 1px rgba(5, 240, 212, 1),
    0px 0px 58px 8px rgba(5, 240, 212, 0.4),
    inset 0px 0px 58px 8px rgba(5, 240, 212, 0.1);

  -webkit-box-shadow: 0px 0px 0px 8px rgba(5, 240, 212, 1),
    0px 0px 1px 1px rgba(5, 240, 212, 0.1),
    inset 0px 0px 1px 1px rgba(5, 240, 212, 1),
    0px 0px 58px 1px rgba(5, 240, 212, 0.4),
    inset 0px 0px 58px 1px rgba(5, 240, 212, 0.1); */
  transition: box-shadow 0.5s ease-in-out;
  box-shadow: 0px 0px 0px 1px rgba(5, 240, 212, 1),
    0px 0px 1px 1px rgba(5, 240, 212, 0.1),
    inset 0px 0px 1px 1px rgba(5, 240, 212, 1),
    0px 0px 58px 1px rgba(5, 240, 212, 0.4),
    inset 0px 0px 58px 1px rgba(5, 240, 212, 0.1);
}

.moreNews {
  color: rgb(5, 240, 212);
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 70%;
  line-height: normal;
}
