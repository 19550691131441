.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 320px;
  height: 100%;
  background-color: #181919;
  border-radius: 20px;
}
.root > h6 {
  margin: 0;
}

.image > img {
  max-width: 320px;
}
.font {
  color: var(--yellow);
  font-size: 16px;
  font-family: "Oswald", sans-serif;
  padding: 16px 0px;
  margin: 0;
}

.leerMas {
  color: var(--green) !important;
  font-size: 20px !important;
  font-family: "Oswald", sans-serif !important;
}

.chevronRight {
  width: 10px;
  margin-left: 10px;
  position: relative;
  bottom: -2px;
}
