/* :root {
  --yellow: #ffef34;
  --white: #fffeed;
  --grey: #323231;
  --green: #05f0d4;
} */
.caption {
  display: block;
  width: fit-content;
  font-size: 20px;
}

.backgroundVideo {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: -2;
  top: 0;
  left: 0;
  transition: 0.1s linear;
}

.video {
  position: absolute;
  left: -60%;
}

.backgroundHover {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 0;
  background-color: rgba(24, 25, 25, 0.8);
  animation-name: overlay;
  animation-duration: 2.5s;
  animation-timing-function: ease-in;
}

.backgroundHoverOutSet {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: -1;
  background-color: #181919;
}

.h1_hidden {
  font-size: 0.1px;
}

.hero_title {
  font-weight: 400;
  font-family: "Apercu Light";
}

.headers_mobile {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  transition: 0.1s linear;
}

.headers_mobile > h1 {
  color: var(--white);
  font-weight: 400;
  margin: 8px 25px 12px 36px;
  font-size: 39px;
  line-height: normal;
  overflow: hidden;
  animation: fadeUpInH2 1s ease-in;
}

.homeCTA {
  margin: 24px 10px 0 42px;
  border: 2px solid var(--green);
  box-shadow: inset 0 0 0 0 var(--green);
  transition: ease-in-out 0.5s;
  border-radius: 4px;
  cursor: pointer;
}

.homeCTA:hover {
  box-shadow: inset 300px 0 0 0 var(--green);
  color: black;
}

.homeCTA > p {
  padding: 0px 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 300;
  color: var(--green);
  transition: ease-in-out 0.5s;
  font-size: 20px;
}

.homeCTA:hover > p {
  color: black;
}
.container_news_content {
  width: 100%;
}

.newsAnchor {
  text-decoration: none;
}

.quotes {
  width: 200px;
  margin-left: -250px;
  margin-bottom: 60px;
}

.quotesDesktop {
  display: none;
}

.container_mas_noticiasDesktop {
  display: none;
}

.container_mas_noticias {
  margin: -20px 0 56px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  animation-name: grow;
  animation-duration: 1s;
  animation-delay: 1.5s;
  animation-timing-function: ease-in-out;
}

.container_mas_noticias img {
  width: 34px;
  height: 24px;
}

.wrapper_testimonials {
  margin: 60px 36px 34px 36px;
}

.container_testimonial {
  margin-bottom: 56px;
}

.container_testimonial_last {
  margin-bottom: 48px;
}

.container_testimonial_last h4 {
  text-align: left;
  font-family: "Apercu Bold";
  color: var(--white);
  margin-bottom: 8px;
  font-weight: 600;
}

.container_testimonial_last h6 {
  text-align: left;
  text-transform: uppercase;
  color: #a5a5a5;
  font-family: "Apercu Light";
  margin-top: 0px;
  line-height: 12px;
  margin-top: 16px;
  margin-bottom: 24px;
}

.container_testimonial_last p {
  text-align: left;
  color: var(--white);
  margin-top: 0px;
}

.container_testimonial h4 {
  text-align: left;
  font-family: "Apercu Bold";
  color: var(--white);
  margin-bottom: 8px;
  font-weight: 600;
}

.container_testimonial h6 {
  text-align: left;
  text-transform: uppercase;
  color: #a5a5a5;
  font-family: "Apercu Light";
  margin-top: 0px;
  line-height: 12px;
  margin-top: 16px;
  margin-bottom: 24px;
}

.container_testimonial p {
  text-align: left;
  color: var(--white);
  margin-top: 0px;
}

.leerMas {
  color: var(--green) !important;
  font-size: 20px !important;
  font-family: "Apercu Regular";
}

.chevronRight {
  width: 10px;
  margin-left: 10px;
  position: relative;
  bottom: -2px;
}

.growAnimation {
  animation-name: grow;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

.container_last_projects_text > h3 {
  margin: 50px 36px 0px 36px;
  padding-bottom: 64px;
  text-align: left;
  font-weight: 400;
  color: var(--white);
}

.container_gallery_left {
  display: flex;
  flex-direction: column;
}

.containerImageOne {
  width: auto;
}

.card_project_1 {
  transition: 0.3s;
  position: relative;
}

.card_project_2 {
  transition: 0.3s;
  position: relative;
}

.card_project_3 {
  transition: 0.3s;
  position: relative;
  width: -webkit-fill-available;
}

.card_project_4 {
  transition: 0.3s;
  position: relative;
}

.card_project_title {
  margin: 0 20px 0px 0;
  font-size: 24px;
  color: var(--white);
}

.card_project_year {
  margin: 0px 177px 73px 2px;
  font-size: 18px;
  color: #a5a5a5;
}

.container_with_arrow svg {
  width: 10px;
}

.container_with_arrow img {
  padding-right: 10px;
}

.projImage {
  transition: 0.5s;
}

.projImage:hover {
  transform: scale(1.1);
  transition: 0.5s;
}

.containerImageProject {
  /* width: 400px; */
  /* overflow: hidden; */
  border-radius: 4px 0 0 4px;
  /* position: absolute;
  right: 0; */
}

.container_gallery_1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}

.card_project_1 {
  width: 50%;
}
.card_project_2 img {
  width: 100%;
  /* height: 500px; */
}
.card_project_3 img,
.card_project_4 img {
  box-sizing: border-box;
  display: block;
  width: -webkit-fill-available;
}

.container_mas_proyectos {
  margin: 40px 0 49px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: var(--white);
  animation-name: grow;
  animation-duration: 0.5s;
  animation-delay: 0.5s;
  animation-timing-function: ease-in-out;
}

.container_mas_proyectos img {
  width: 30px;
  height: 30px;
}

.container_with_arrow {
  display: flex;
  align-items: center;
  margin: 6px 8px 5px 13px;
  color: var(--green);
}

.container_with_arrow > p {
  padding-right: 10px;
  font-size: 16px;
}

.container_academia {
  margin: 40px 0 49px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--white);
  animation-name: grow;
  animation-duration: 0.5s;
  animation-delay: 0.5s;
  animation-timing-function: ease-in-out;
}

.imgAcademiaContainer {
  width: fit-content;
  height: auto;
}

.imgAcademia {
  width: auto;
  height: 375px;
  border: 10px solid white;
}

.imgAcademia > img {
  width: 300px;
}

.academia_text {
  margin: 0px 36px 0px 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fdfad6;
  max-width: 300px;
}

.textAndCube {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cube {
  display: none;
}

.cubeMobile {
  position: absolute;
  left: 66px;
}

.cubeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container_equipo {
  padding-top: 32px;
  padding-bottom: 72px;
  margin-left: -8px;
  margin-right: -8px;
}

.container_equipo > h3 {
  margin: 0 36px 16px 44px;
  color: #fffeed;
  font-weight: 400;
}

.container_sobre_nosotros {
  background-color: #232323;
  padding-top: 72px;
  padding-bottom: 72px;
  margin-left: -8px;
  margin-right: -8px;
}

.container_sobre_nosotros > h3 {
  margin: 0 36px 16px 44px;
  color: #fffeed;
  font-weight: 400;
}

.teamHeader {
  margin-top: 32px !important;
}

.sobre_nosotros_text_1 {
  margin: 0px 36px 16px 44px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fdfad6;
  max-width: 300px;
}

.sobre_nosotros_text_2 {
  margin: 0px 36px 70px 44px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fdfad6;
  max-width: 300px;
}

.equipo_cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: -30px;
}

.container_card_sn {
  width: 288px;
  height: auto;
  margin: 30px 0 0;
  border: solid 1px #fffeed;
}

.container_card_sn > img {
  width: 288px;
  height: 420px;
}

.container_card_sn > h5 {
  margin: 16px 55px 8px 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fffeed;
}

.container_card_sn > p {
  margin: 8px 42px 26px 36px;
  font-family: "Apercu Light";
  font-size: 16px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #a5a5a5;
}

@keyframes overlay {
  0% {
    background-color: rgba(24, 25, 25, 1);
  }

  25% {
    background-color: rgba(24, 25, 25, 1);
  }

  50% {
    background-color: rgba(24, 25, 25, 1);
  }

  100% {
    background-color: rgba(24, 25, 25, 0.8);
  }
}

@keyframes fadeUpInH2 {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeUpInH3 {
  0% {
    opacity: 0;
    padding-top: 45px;
    color: var(--white);
  }

  25% {
    opacity: 0;
    padding-top: 30px;
    color: var(--white);
  }

  50% {
    opacity: 0;
    padding-top: 15px;
    color: var(--grey);
  }

  100% {
    opacity: 1;
    padding-top: 0px;
    color: var(--grey);
  }
}

@keyframes grow {
  from {
    opacity: 0;
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  /* Styles */
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) and (orientation: landscape) {
  .headers_mobile {
    width: 100%;
    height: 100vh;
    margin-left: 30px;
  }

  .headers_mobile > h1 {
    font-size: 31px;
  }

  .wrapper_testimonials {
    margin: 72px 34px 30px 30px;
  }

  .stringsUp {
    margin-bottom: 12px;
  }

  .stringsDown {
    margin-top: 5px;
  }

  .container_mas_noticias {
    margin: 45px 0 30px 0;
  }

  .container_our_services {
    margin: 0 auto;
  }

  .container_sobre_nosotros {
    padding-top: 72px;
  }

  .container_sobre_nosotros > h3 {
    margin-left: 44px;
  }

  .container_last_projects {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }

  /* .container_mas_proyectos { */
    /* width: 50%; */
  /* } */

  .backgroundVideo {
    height: 100vh;
  }

  .backgroundHover {
    height: 101vh;
  }

  .cubeMobile {
    position: absolute;
    margin-left: 400px;
    margin-top: -30px;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .headers_mobile {
    width: 100%;
    height: 100vh;
  }

  .backgroundVideo {
    height: 100vh;
    display: flex;
  }

  .video {
    margin-left: 35%;
    height: 100vh;
  }

  .wrapper_testimonials {
    margin: 80px 34px 160px 30px;
  }

  .stringsUp {
    margin-bottom: 12px;
  }

  .stringsDown {
    margin-top: 5px;
  }

  .container_sobre_nosotros > h3 {
    font-size: 42px;
  }

  .container_sobre_nosotros > p {
    font-size: 18px;
  }

  .container_our_services {
    margin: 0 auto;
  }

  .container_last_projects {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 30px;
  }

  .container_mas_proyectos {
    width: 50%;
  }

  .leftBlock {
    width: 33vw;
    height: 100%;
    background-color: #181919;
    z-index: 99;
  }

  .cubeMobile {
    position: absolute;
    margin-left: 400px;
    margin-top: -30px;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .backgroundVideo {
    height: 102vh;
    width: 102vw;
    display: flex;
  }

  .video {
    margin-left: 90%;
    height: 102vh;
  }

  .leftBlock {
    display: block;
    width: 13vw;
    height: 100%;
    background-color: #181919;
    z-index: 99;
  }

  .backgroundHover {
    width: 102vw;
    height: 102vh;
  }

  .stringsUp {
    margin-bottom: 12px;
  }

  .stringsDown {
    margin-top: 5px;
  }

  .container_our_services {
    margin: 0 auto;
    width: 60%;
  }

  .container_last_projects {
    display: flex;
    justify-content: center;
    padding-bottom: 8px;
  }

  .container_sobre_nosotros {
    margin-right: -28px;
  }

  .sobre_nosotros_text_2 {
    margin-bottom: 0;
  }

  .cubeMobile {
    position: absolute;
    margin-left: 600px;
    margin-top: -30px;
  }
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .backgroundVideo {
    width: 100vw;
    height: 102vh;
    z-index: -2;
  }

  .video {
    position: absolute;
    left: 0px;
    z-index: -1;
  }

  .leftBlock {
    display: block;
    width: 33vw;
    height: 100%;
    background-color: #181919;
    z-index: 999;
  }

  .stringsUp {
    margin-bottom: 12px;
  }

  .stringsDown {
    margin-top: 5px;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  .backgroundVideo {
    height: 100vh;
    z-index: -2;
  }

  .backgroundHover {
    height: 100vh;
  }

  .video {
    position: absolute;
    width: 100vw;
    height: 93vh;
    left: 40%;
    z-index: -1;
  }

  .leftBlock {
    width: 50vw;
    height: 100%;
    background-color: #181919;
    z-index: 999;
  }

  .headers_mobile {
    display: flex;
    margin-left: 56px;
  }

  .logo_wrap img {
    width: 220px;
    margin-left: 20vw;
    margin-bottom: 14px;
  }

  .headers_mobile > h1 {
    font-family: "Apercu Light";
    font-size: 3.5em;
    font-weight: 200;
    margin: 8px 25% 17px 25%;
  }

  .homeCTA {
    margin: 36px 100px 0 25%;
    border: 2px solid var(--green);
    border-radius: 4px;
  }

  .homeCTA > p {
    padding: 0px 36px;
    font-family: "Apercu Light";
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 400;
    color: var(--green);
  }

  .wrapper_testimonials {
    width: 100%;
    margin: 160px 160px 34px 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .container_gallery_1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    gap: 3rem;
  }

  .quotes {
    display: none;
  }

  .quotesDesktop {
    display: block;
    width: 200px;
    margin-left: 14%;
    margin-bottom: 6%;
  }

  .container_mas_noticiasDesktop {
    display: block;
  }

  .quotesSection {
    width: 25%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 3.1%;
  }

  .container_with_arrow > img {
    width: 300px;
  }

  .newsContainer {
    width: 50%;
    margin-right: 10%;
  }

  .marginLeft {
    margin-left: 48px;
  }

  .container_mas_noticias {
    display: none;
  }

  .container_last_projects_text > h3 {
    margin: 36px 36px 0px 36px;
  }

  .container_gallery_three {
    display: flex;
    width: -webkit-fill-available;
  }

  .container_gallery_left {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
  }

  .imgOne {
    max-width: 470px;
  }

  .container_sobre_nosotros {
    padding: 10vh 7vw;
    display: flex;
    flex-direction: column;
    align-content: center;
  }

  .container_sobre_nosotros > h3 {
    padding-bottom: 32px;
  }

  .sobre_nosotros_text_1 {
    margin: 0px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fdfad6;
    max-width: 300px;
    padding-bottom: 24px;
  }

  .sobre_nosotros_text_2 {
    margin: 0px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fdfad6;
    max-width: 300px;
  }

  .container_equipo {
    padding: 7vh 7vw 10vh 7vw;
  }

  .projectsIcon {
    width: 40px !important;
  }

  .container_sobre_nosotros > h3 {
    margin: 0;
  }

  .textAndCube {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .cube {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 12px;
    padding-right: 300px;
  }

  .cubeMobile {
    display: none;
  }

  .proyectsSectionContainer {
    padding: 10vh 7vw 8vh 7vw;
  }

  .container_last_projects {
    margin-top: 5%;
  }

  .container_last_projects_text > h5 {
    font-size: 58px;
  }

  .container_with_arrow {
    padding: 10px;
    margin: 0px 0 0 0;
    cursor: pointer;
  }

  .container_with_arrow:hover {
    background-color: var(--grey);
    font-size: 400px;
    border-radius: 4px;
    transition: all 0.5s ease-in-out;
  }

  .container_with_arrow img {
    width: 60px;
    height: 40px;
    margin-right: 10px;
  }

  .container_with_arrow p {
    font-size: 24px;
    margin: 0;
    font-family: "Apercu Regular";
  }

  .container_with_arrow svg {
    width: 30px;
    padding-right: 10px;
  }

  .containerImageProject1 {
    width: 50%;
    /* height: 233px; */
  }

  .containerImageProject2 {
    width: 80%;
    /* height: 233px; */
  }

  .equipo_cards {
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 80px;
    margin-bottom: 80px;
  }

  @keyframes fadeUpInH3 {
    0% {
      opacity: 0;
    }

    25% {
      opacity: 0.2;
    }

    50% {
      opacity: 0.6;
    }

    100% {
      opacity: 1;
    }
  }
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  .backgroundVideo {
    width: 100vw;
    height: 102vh;
    z-index: -2;
  }

  .backgroundHover {
    height: 102vh;
  }

  .video {
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 40%;
    z-index: -1;
  }
}
