.loaderContainer {
  width: 100vw;
  height: 60px;
  display: flex;
  position: relative;
  top: -170px;  
}

.loader {
  width: 38px;
  height: 62px;
  position: relative;
  box-sizing: border-box;
  border: 2px solid rgba(189,189,189, 0.3);
  margin: auto;
  border-radius: 50% 50% 50% 50% / 25% 25% 25% 25%;
}

.loader::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  width: 4px;
  height: 4px;
  background: rgba(189,189,189, 0.3);
  border-radius: 10px;
  animation: scrollDown 1.5s linear infinite;
}

@keyframes scrollDown {
  0% {
    top: 12px;
    height: 4px;
    opacity: 1;
  }

  33% {
    top: 12px;
    height: 30px;
  }

  66% {
    top: 40px;
    height: 10px;
    opacity: 1;
  }

  100% {
    top: 40px;
    height: 0px;
    opacity: 0;
  }
}

@media only screen and (min-width : 1224px) {
  .loaderContainer {
    width: 100vw;
    height: 60px;
    display: flex;
    position: relative;
    top: -110px;  
  }
  
  .loader {
    width: 48px;
    height: 78px;
    position: relative;
    box-sizing: border-box;
    border: 2px solid rgba(189,189,189, 0.3);
    margin: auto;
    border-radius: 50% 50% 50% 50% / 25% 25% 25% 25%;
  }
  
  .loader::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 20px;
    transform: translateX(-50%);
    width: 4px;
    height: 4px;
    background: rgba(189,189,189, 0.3);
    border-radius: 10px;
    animation: scrollDown 1.5s linear infinite;
  }

  @keyframes scrollDown {
    0% {
      top: 15px;
      height: 4px;
      opacity: 1;
    }
  
    33% {
      top: 15px;
      height: 40px;
    }
  
    66% {
      top: 50px;
      height: 10px;
      opacity: 1;
    }
  
    100% {
      top: 56px;
      height: 4px;
      opacity: 0;
    }
  }
}