.root {
  display: flex;
  flex-direction: column;
  border: 1px solid antiquewhite;
  width: 99%;
  height: -webkit-fill-available;
}
.container {
  width: 87%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
}

.container > h5 {
  margin: 0;
}

.container > p {
  margin: 0;
}

.image {
  width: fill-available;
  height: 200px;
  order: 0 !important;
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) and (orientation: landscape) {
  .root {
    flex-direction: row;
    height: auto;
    max-height: 100%;
  }
  .container {
    width: auto;
  }
  .image {
    width: 100%;
    max-width: 226px;
    height: auto;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .root {
    flex-direction: row;
    height: auto;
    max-height: 100%;
  }

  .container {
    width: 60%;
    border: 1px solid antiquewhite;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
  }

  .container > h5 {
    margin: 0;
  }

  .container > p {
    margin: 0;
  }

  .image {
    width: auto;
    max-height: 330px;
    height: 330px;
    display: flex;
    justify-content: flex-end;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .root {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    max-height: 330px;
    height: 330px;
    overflow: hidden;
    height: -webkit-fill-available;
  }

  .container {
    width: 60%;
    border: 1px solid antiquewhite;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
  }

  .container > h5 {
    margin: 0;
  }

  .container > p {
    margin: 0;
  }

  .image {
    width: auto;
    max-height: 330px;
    height: 330px;
    display: flex;
    justify-content: flex-end;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  .root {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-height: 330px;
    min-height: 330px;
    /* overflow: hidden; */
    height: -webkit-fill-available;
  }

  .container {
    /* width: 60%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    margin-right: 160px;
    margin-left: 40px;
  }

  .container > h5 {
    margin: 0;
  }

  .container > p {
    margin: 0;
  }

  .image {
    min-width: 536px;
    max-height: 330px;
    height: 330px;
    display: flex;
    justify-content: flex-end;
  }
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* .root {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    max-height: 330px;
    height: 330px;
    overflow: hidden;
    height: -webkit-fill-available;
  }

  .container {
    width: 60%;
    border: 1px solid antiquewhite;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
  }

  .container > h5 {
    margin: 0;
  }

  .container > p {
    margin: 0;
  }

  .image {
    width: auto;
    max-height: 330px;
    height: 330px;
    display: flex;
    justify-content: flex-end;
  } */
}
